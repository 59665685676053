import { Injectable } from '@angular/core';
import { DynamicDataElementTypeSetup } from '@data-management/dynamic-data-setup/base/dynamic-data';
import { DynamicTableOptions } from '@models/dynamic-table/dynamic-table-options';
import { DynamicFormOptions } from '@models/dynamic-form/dynamic-form-properties';
import { DataElementType } from '@constants/data-element-types';
import { Store } from '@ngrx/store';
import { FDMState } from '@store/reducers/index';
import { Config } from '@models/fabrication/config';
import { EnvironmentConstants } from '@constants/environment-constants';
import { TranslateService } from '@ngx-translate/core';
import { FabricationReference } from '@models/forge-content/references';
import { InvalidDataErrorService } from '@services/invalid-data-error.service';
import { EnvironmentService } from '@services/environment.service';
import { SchemaService } from '@services/schema.service';
import { Observable } from 'rxjs';
import { ForgeContentDataElement } from '@models/forge-content/forge-content-data-element';

@Injectable()
export class DynamicImageFileSetup extends DynamicDataElementTypeSetup<ForgeContentDataElement> {
  constructor(
    store$: Store<FDMState>,
    translate: TranslateService,
    invalidDataService: InvalidDataErrorService<ForgeContentDataElement>,
    schemaService: SchemaService,
    environmentService: EnvironmentService
  ) {
    super(store$, translate, invalidDataService, schemaService, environmentService);
  }

  get helpLinkId(): string {
    return null;
  }

  setupOptions() {
    this._options = {
      dataType: DataElementType.Image,
      excludeDataTypeFromBulkLoad: true,
      dependentDataTypes: [],
      supportsDynamicUpdates: false,
      createNewInstance: null,
      sortFields: [],
      selectors: {
        selectAll: () => null,
        selectById: () => null,
      },
      actions: {
        loadAllAction: (config: Config) => null,
        loadSuccessAction: () => null,
        deleteDataSuccessAction: () => null,
        addDataSuccessAction: () => null,
        updateDataSuccessAction: () => null,
        updateDataReferencesAction: () => null,
        createModelAction: null,
        editModelAction: null,
        copyModelAction: null,
        deleteModelsAction: null,
        fixModelAction: null,
      },
      fcs: {
        dataTypeExternalNodeId: EnvironmentConstants.FCS_NODE_ID_IMAGE_FILES,
        schemas: null,
      },
    };
  }

  getDynamicTableOptions(): Observable<DynamicTableOptions<ForgeContentDataElement>> {
    return null;
  }

  getDynamicFormOptions(): Observable<DynamicFormOptions<ForgeContentDataElement>> {
    return null;
  }

  fixMissingReferences(fabricationReferences: FabricationReference[]): FabricationReference[] {
    return fabricationReferences || [];
  }

  dataFixes(): void {
    //
  }

  getIconName(): string {
    return null;
  }

  isFixable(): boolean {
    return false;
  }

  getInvalidDataErrors(): string {
    return null;
  }

  requiresBinaryUpgrade(/*dataElement: unknown*/): boolean {
    return false;
  }
}
