import { Injectable } from '@angular/core';
import { DynamicDataElementTypeSetup } from '@data-management/dynamic-data-setup/base/dynamic-data';
import { DynamicTableOptions, ColumnDefinition } from '@models/dynamic-table/dynamic-table-options';
import { DynamicFormOperationType } from '@models/dynamic-form/dynamic-form-types';
import { DynamicFormOptions, DynamicFormStyle } from '@models/dynamic-form/dynamic-form-properties';
import { DataElementType } from '@constants/data-element-types';
import { Store } from '@ngrx/store';
import { FDMState } from '@store/reducers/index';
import { Config } from '@models/fabrication/config';
import { FabricationRate } from '@models/fabrication/labour-rate';
import { EnvironmentConstants } from '@constants/environment-constants';
import { DynamicGraphOptions } from '@models/dynamic-graph/dynamic-graph-options';
import { selectCurrentFabricationRates } from '@store/selectors/configs.selectors';
import { selectFabricationRateById } from '@store/selectors/fabrication-rate.selectors';
import {
  LoadFabricationRates,
  LoadFabricationRatesSuccess,
} from '@store/actions/fabrication-rate.action';
import { UpdateConfigFabricationRateIds } from '@store/actions/configs.action';
import { TranslateService } from '@ngx-translate/core';
import { selectInternalInvalidData } from '@store/selectors/invalid-data.selectors';
import { FabricationReference } from '@models/forge-content/references';
import { InvalidDataErrorService } from '@services/invalid-data-error.service';
import { EnvironmentService } from '@services/environment.service';
import { SchemaService } from '@services/schema.service';
import { Observable, map, of } from 'rxjs';

@Injectable()
// todo: implement table and form setup
export class DynamicFabricationRateSetup extends DynamicDataElementTypeSetup<FabricationRate> {
  type: DataElementType.FabricationRate;

  constructor(
    store$: Store<FDMState>,
    translate: TranslateService,
    invalidDataService: InvalidDataErrorService<FabricationRate>,
    schemaService: SchemaService,
    environmentService: EnvironmentService
  ) {
    super(store$, translate, invalidDataService, schemaService, environmentService);
  }

  get helpLinkId(): string {
    return '';
  }

  setupOptions() {
    this._options = {
      dataType: DataElementType.FabricationRate,
      dependentDataTypes: [],
      createNewInstance: () => new FabricationRate(),
      sortFields: ['value', 'name'],
      supportsDynamicUpdates: false,
      selectors: {
        selectAll: (includeInvalidData: boolean) =>
          this.store$.select(selectCurrentFabricationRates(includeInvalidData)),
        selectById: (id: string, getInternalInvalidData?: boolean) =>
          getInternalInvalidData
            ? this.store$.select(selectInternalInvalidData(id, this.fixMissingReferences))
            : this.store$.select(selectFabricationRateById(id)),
      },
      actions: {
        loadAllAction: (config: Config) =>
          this.store$.dispatch(new LoadFabricationRates({ config })),
        loadSuccessAction: () => new LoadFabricationRatesSuccess(),
        deleteDataSuccessAction: () => null,
        addDataSuccessAction: () => null,
        updateDataSuccessAction: () => null,
        updateDataReferencesAction: (
          config: Config,
          dataIds: string[],
          deleteReferences: boolean
        ) =>
          new UpdateConfigFabricationRateIds(
            {
              id: config.externalId,
              changes: dataIds,
            },
            deleteReferences
          ),
        createModelAction: null,
        editModelAction: null,
        copyModelAction: null,
        deleteModelsAction: () => null,
        fixModelAction: null,
      },
      fcs: {
        dataTypeExternalNodeId: EnvironmentConstants.FCS_NODE_ID_FABRICATIONRATES,
        schemas: [
          {
            dataType: DataElementType.FabricationRate,
            schema: {
              namespace: EnvironmentConstants.FSS_SCHEMA_NAMESPACE,
              version: EnvironmentConstants.FSS_SCHEMA_FABRICATION_RATE_VERSION,
              type: EnvironmentConstants.FSS_SCHEMA_FABRICATION_RATE,
            },
          },
        ],
      },
    };
  }

  getDynamicTableOptions(): Observable<DynamicTableOptions<FabricationRate>> {
    const columns: ColumnDefinition[] = [];
    return of(this.createDynamicTableOptions(columns));
  }

  getDynamicFormOptions(
    formOperation: DynamicFormOperationType,
    modelId: string
  ): Observable<DynamicFormOptions<FabricationRate>> {
    const uniqueFieldRestrictions = ['name'];
    return this.getFormModel(formOperation, modelId).pipe(
      map((model: FabricationRate) => {
        return {
          model,
          formOperation,
          applyModelAction: this.getFormApplyAction(formOperation),
          isReadOnly: formOperation === 'view',
          uniqueFields: {
            fields: uniqueFieldRestrictions,
            allElements: () => this.options.selectors.selectAll(true),
          },
          formStyle: DynamicFormStyle.SIMPLE,
        };
      })
    );
  }

  getDynamicGraphOptions(): DynamicGraphOptions {
    return {
      nodeInfoFields: ['name', 'value'],
      isReplaceable: true,
      isRemovable: false,
      isEditable: false,
      upstreamReferenceDataTypes: () => [DataElementType.Connector],
      isDownstreamRefBlocked: () => true,
    };
  }

  fixMissingReferences(fabricationReferences: FabricationReference[]): FabricationReference[] {
    return fabricationReferences || [];
  }

  dataFixes(): void {
    //
  }

  getIconName(): string {
    return null;
  }

  isFixable(): boolean {
    return false;
  }

  getInvalidDataErrors(): string {
    return null;
  }

  requiresBinaryUpgrade(/*dataElement: FabricationRate*/): boolean {
    return false;
  }
}
