import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class DynatraceService {
  private isInitialised = false;

  constructor(private environmentService: EnvironmentService) {}

  // todo: ideally dynatrace script should be loaded independently of the app
  injectScript(): string {
    if (this.isInitialised) {
      return;
    }

    if (!this.environmentService.environment.isLocal) {
      const dynatraceScript = document.createElement('script');
      dynatraceScript.src = this.environmentService.environment.dynatraceScriptUrl;
      dynatraceScript.setAttribute('crossorigin', 'anonymous');
      dynatraceScript.setAttribute('type', 'text/javascript');
      document.head.appendChild(dynatraceScript);

      this.isInitialised = true;
    }
  }
}
