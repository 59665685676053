import { Injectable } from '@angular/core';
import { EnvironmentSettings, CosEnvironment } from '@models/environment/environment-settings';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { EnvironmentConstants as ec } from '@constants/environment-constants';
import { NavigationConstants as nc } from '@constants/navigation-constants';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  private readonly devAppMoniker = ec.COS_APP_MONIKER_DEV;
  private readonly stagingAppMoniker = ec.COS_APP_MONIKER_STG;
  private readonly productionAppMoniker = ec.COS_APP_MONIKER_PROD;
  private clientData: any = null;
  private cosMoniker = null;
  private cosEnvironment: CosEnvironment = null;
  private isDev: boolean;
  private isStaging: boolean;
  private isProduction: boolean;
  private selectedEnvironment: EnvironmentSettings = null;

  public get environment(): EnvironmentSettings {
    return this.selectedEnvironment;
  }

  public constructor(private translate: TranslateService) {
    this.setupEnvironment();
  }

  private setupEnvironment(): void {
    // get client data from window object
    // this is injected from the nodejs server app
    this.clientData = (window as any)['client-data'];
    this.cosMoniker = this.clientData && this.clientData['cos-moniker'];

    const hostname = window.location.hostname;

    if (['mepce.autodesk.com', 'fabdm.autodesk.com'].includes(hostname)) {
      this.cosMoniker = 'fabdm-p-ue1';
    } else if (['mepce-stg.autodesk.com', 'fabdm-stg.autodesk.com'].includes(hostname)) {
      this.cosMoniker = 'fabdm-s-ue1';
    } else {
      this.cosMoniker = 'fabdm-c-uw2';
    }

    if (this.cosMoniker && this.parseAppMoniker()) {
      this.setCosEnvironment();
      this.selectedEnvironment = this.createEnvironment();
    } else {
      throw new Error(this.translate.instant(LC.APP.ERROR_ENVIRONMENT_SETTINGS));
    }
  }

  private parseAppMoniker(): boolean {
    this.isDev = this.cosMoniker.toLowerCase() === this.devAppMoniker;
    this.isStaging = this.cosMoniker.toLowerCase() === this.stagingAppMoniker;
    this.isProduction = this.cosMoniker.toLowerCase() === this.productionAppMoniker;
    return this.isDev || this.isStaging || this.isProduction;
  }

  private setCosEnvironment(): void {
    this.cosEnvironment =
      (this.isDev && 'dev') || (this.isStaging && 'stg') || (this.isProduction && 'prod') || null;
  }

  private isLocalEnvironment(): boolean {
    return window.location.hostname === 'localhost';
  }

  private createEnvironment(): EnvironmentSettings {
    const isDevOrStaging = this.isDev || this.isStaging;
    const environment: EnvironmentSettings = {
      isLocal: this.isLocalEnvironment(),
      cosAppMoniker: this.cosMoniker,
      cosEnvironment: this.cosEnvironment,
      adpEnvironment: this.isProduction ? 'prd' : 'stg',
      authEnvironment: this.isProduction ? 'prd' : 'stg',
      clientId: this.isProduction ? ec.APP_CLIENT_ID_PROD : ec.APP_CLIENT_ID_STG,
      dynatraceScriptUrl:
        (this.isDev && ec.DYNATRACE_SCRIPT_URL_DEV) ||
        (this.isStaging && ec.DYNATRACE_SCRIPT_URL_STG) ||
        (this.isProduction && ec.DYNATRACE_SCRIPT_URL_PROD),
      binaryTaskManagerEndpointV2:
        (this.isDev && ec.URL_BINARY_TASK_MANAGER_DEV_V2) ||
        (this.isStaging && ec.URL_BINARY_TASK_MANAGER_STG_V2) ||
        (this.isProduction && ec.URL_BINARY_TASK_MANAGER_PROD_V2),
      accounts: {
        userProfileUrl:
          (isDevOrStaging && ec.URL_USER_PROFILE_STG) ||
          (this.isProduction && ec.URL_USER_PROFILE_PROD),
        logoutUrl:
          (isDevOrStaging && ec.URL_LOGOUT_STG) || (this.isProduction && ec.URL_LOGOUT_PROD),
        userAccountUrl:
          (isDevOrStaging && ec.URL_ACCOUNTS_STG) || (this.isProduction && ec.URL_ACCOUNTS_PROD),
        oxygenAnalyticsIdUrl:
          (isDevOrStaging && ec.URL_OXYGEN_ANALYTICS_ID_STG) ||
          (this.isProduction && ec.URL_OXYGEN_ANALYTICS_ID_PROD),
      },
      fcs: {
        fcsUrl: (isDevOrStaging && ec.URL_FCS_STG) || (this.isProduction && ec.URL_FCS_PROD),
        serviceDataNodeId: ec.FCS_NODE_ID_SERVICE_DATA,
        serviceNodeId: ec.FCS_NODE_ID_SYSTEMINFOS,
        partTemplateNodeId: ec.FCS_NODE_ID_SERVICE_TEMPLATE,
        contentDataNodeId: ec.FCS_NODE_ID_CONTENT_DATA,
        databaseNodeId: ec.FCS_NODE_ID_DATABASE,
        specsNodeId: ec.FCS_NODE_ID_SPECIFICATIONINFOS,
        insulationSpecsNodeId: ec.FCS_NODE_ID_INS_SPECIFICATIONINFOS,
        configMetaNodeId: ec.FCS_NODE_ID_CONFIG_META,
        configMetaContentId: ec.FCS_CONTENT_ID_CONFIG_META,
        systemInfoNodeId: ec.FCS_NODE_ID_SYSTEMINFOS,
        connectorInfoNodeId: ec.FCS_NODE_ID_CONNECTORINFOS,
        damperInfoNodeId: ec.FCS_NODE_ID_DAMPERINFOS,
        stiffenerInfoNodeId: ec.FCS_NODE_ID_STIFFENERINFOS,
        materialsNodeId: ec.FCS_NODE_ID_MATERIALS,
        materialSpecsNodeId: ec.FCS_NODE_ID_MATERIAL_SPECS,
        invalidDataNodeId: ec.FCS_NODE_ID_INVALID_DATA,
        stiffenerSpecsNodeId: ec.FCS_NODE_ID_STIFFENER_SPECS,
      },
      schema: {
        schemaUrl: (isDevOrStaging && ec.URL_FSS_STG) || (this.isProduction && ec.URL_FSS_PROD),
      },
      activity: {
        activityUrl:
          (isDevOrStaging && ec.URL_ACTIVITY_STG) || (this.isProduction && ec.URL_ACTIVITY_PROD),
        activityNamespace: this.cosMoniker,
      },
      notifications: {
        notificationUrl:
          (isDevOrStaging && ec.URL_NOTIFICATION_STG) ||
          (this.isProduction && ec.URL_NOTIFICATION_PROD),
      },
      navSubModuleHelp: {
        helpUrl:
          (isDevOrStaging && nc.SIDE_NAV_HELP_LINK_STG) ||
          (this.isProduction && nc.SIDE_NAV_HELP_LINK_PROD),
        feedbackUrl: ec.SIDE_NAV_SUB_MODULE_FEEDBACK_LINK,
      },
      fabdmCm: {
        baseUrl:
          (this.isDev && ec.URL_FABDM_CM_DEV) ||
          (this.isStaging && ec.URL_FABDM_CM_STG) ||
          (this.isProduction && ec.URL_FABDM_CM_PROD),
      },
      fabdmTm: {
        baseUrl:
          (this.isDev && ec.URL_FABDM_TM_DEV) ||
          (this.isStaging && ec.URL_FABDM_TM_STG) ||
          (this.isProduction && ec.URL_FABDM_TM_PROD),
      },
      helpLinks: {
        baseUrl:
          (isDevOrStaging && ec.URL_BASE_HELP_LINKS_STG) ||
          (this.isProduction && ec.URL_BASE_HELP_LINKS_PROD),
      },
    };

    return environment;
  }
}
