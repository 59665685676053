export class FeatureFlagConstants {
  static readonly MULTI_DELETE = 'multi-delete';
  static readonly DAMPERS = 'dampers';
  static readonly STIFFENERS = 'stiffeners';
  static readonly FDM_DISABLE = 'fdm-disable';
  static readonly THEME_SETTINGS = 'fdm-theme-settings';
  static readonly FDM_SWITCH_VIEW = 'fdm-switch-view';
  static readonly LEARNING_PANEL = 'fdm-learning-panel';
  static readonly STIFFENER_SPECS = 'stiffener-specs';
  static readonly ROW_REORDERING = 'row-reordering';
  static readonly CONNECTOR_PREVIEW = 'connector-preview';
}
