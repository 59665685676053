import { OnInit, Component, OnDestroy } from '@angular/core';
import { DynamicFormBaseCustomComponent } from '@shared/components/dynamic-form/dynamic-form-base-custom-component';
import { FDMState } from '@store/reducers';
import { Store } from '@ngrx/store';
import { Part } from '@models/fabrication/part';
import { ActivatedRoute } from '@angular/router';
import { DynamicFormReference } from '@data-management/dynamic-data-setup/base/dynamic-data';
import { filter, Subscription } from 'rxjs';
import { DynamicFormCustomUpdateEvent } from '@models/dynamic-form/dynamic-form-properties';

@Component({
  template: `
    <fab-part-connectors-form
      [source]="part"
      [connectorsData]="connectorsData"
      [isReadOnly]="isReadOnly"
      (updateSource)="updatePartSource($event)"
      (lmvPreviewSource)="lmvPreviewSource($event)"
    />
  `,
})
export class PartConnectorsFormComponent
  extends DynamicFormBaseCustomComponent<Part>
  implements OnInit, OnDestroy
{
  part: Part;
  connectorsData: DynamicFormReference[];
  customModelChangesSubscription: Subscription;
  lmvPreviewSource: (previewPart: Part) => void;

  constructor(private route: ActivatedRoute, store$: Store<FDMState>) {
    super(store$);
  }

  ngOnInit(): void {
    const { connectorsData, lmvPreviewSource } =
      this.route.snapshot.data.partMap.formOptions.additionalData;

    this.connectorsData = connectorsData;
    this.lmvPreviewSource = lmvPreviewSource;

    this.customModelChangesSubscription = this.formModelUpdater
      .pipe(
        filter(
          (changeEvent: DynamicFormCustomUpdateEvent) =>
            changeEvent.isFirstUpdate || changeEvent.subscriptionToken !== this.subscriptionToken
        )
      )
      .subscribe((changeEvent: DynamicFormCustomUpdateEvent) => {
        this.part = changeEvent.data;
      });
  }

  ngOnDestroy() {
    this.customModelChangesSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  updatePartSource(updatedSource: Part) {
    this.part = updatedSource;
    this.updateSource(updatedSource);
  }
}
