import { Injectable } from '@angular/core';
import { DynamicDataElementTypeSetup } from '@data-management/dynamic-data-setup/base/dynamic-data';
import { DynamicTableOptions } from '@models/dynamic-table/dynamic-table-options';
import { DynamicFormOptions } from '@models/dynamic-form/dynamic-form-properties';
import { DataElementType } from '@constants/data-element-types';
import { Store } from '@ngrx/store';
import { FDMState } from '@store/reducers/index';
import { Config } from '@models/fabrication/config';
import { EnvironmentConstants } from '@constants/environment-constants';
import { DBFile } from '@models/fabrication/db-file';
import { selectCurrentConfigDBFiles } from '@store/selectors/configs.selectors';
import { selectDBFileById } from '@store/selectors/db-file.selectors';
import {
  LoadDBFiles,
  LoadDBFilesSuccess,
  UpdateDBFileSuccess,
} from '@store/actions/db-file.action';
import { UpdateConfigDatabaseFileIds } from '@store/actions/configs.action';
import { StorageFileType } from '@models/fabrication/files';
import { TranslateService } from '@ngx-translate/core';
import { selectInternalInvalidData } from '@store/selectors/invalid-data.selectors';
import { FabricationReference } from '@models/forge-content/references';
import { InvalidDataErrorService } from '@services/invalid-data-error.service';
import { EnvironmentService } from '@services/environment.service';
import { SchemaService } from '@services/schema.service';
import { Observable } from 'rxjs';

@Injectable()
export class DynamicDbFileSetup extends DynamicDataElementTypeSetup<DBFile> {
  constructor(
    store$: Store<FDMState>,
    translate: TranslateService,
    invalidDataService: InvalidDataErrorService<DBFile>,
    schemaService: SchemaService,
    environmentService: EnvironmentService
  ) {
    super(store$, translate, invalidDataService, schemaService, environmentService);
  }

  get helpLinkId(): string {
    return null;
  }

  setupOptions() {
    this._options = {
      dataType: DataElementType.DBFile,
      dependentDataTypes: [],
      supportsDynamicUpdates: true,
      createNewInstance: null,
      sortFields: [],
      bulkLoadFileTypesSupported: [StorageFileType.DbFile],
      selectors: {
        selectAll: () => this.store$.select(selectCurrentConfigDBFiles),
        selectById: (id: string, getInternalInvalidData?: boolean) =>
          getInternalInvalidData
            ? this.store$.select(selectInternalInvalidData(id, this.fixMissingReferences))
            : this.store$.select(selectDBFileById(id)),
      },
      actions: {
        loadAllAction: (config: Config) => this.store$.dispatch(new LoadDBFiles({ config })),
        loadSuccessAction: () => new LoadDBFilesSuccess(),
        deleteDataSuccessAction: () => null,
        addDataSuccessAction: () => null,
        updateDataSuccessAction: () => new UpdateDBFileSuccess(),
        updateDataReferencesAction: (
          config: Config,
          dataIds: string[],
          deleteReferences: boolean
        ) =>
          new UpdateConfigDatabaseFileIds(
            {
              id: config.externalId,
              changes: dataIds,
            },
            deleteReferences
          ),
        createModelAction: null,
        editModelAction: null,
        copyModelAction: null,
        deleteModelsAction: null,
        fixModelAction: null,
      },
      fcs: {
        dataTypeExternalNodeId: EnvironmentConstants.FCS_NODE_ID_CORE_FILES,
        schemas: null,
      },
    };
  }

  getDynamicTableOptions(): Observable<DynamicTableOptions<DBFile>> {
    return null;
  }

  getDynamicFormOptions(): Observable<DynamicFormOptions<DBFile>> {
    return null;
  }

  fixMissingReferences(fabricationReferences: FabricationReference[]): FabricationReference[] {
    return fabricationReferences || [];
  }

  dataFixes(): void {
    //
  }

  getIconName(): string {
    return null;
  }

  isFixable(): boolean {
    return false;
  }

  getInvalidDataErrors(): string {
    return null;
  }

  requiresBinaryUpgrade(/*dataElement: DBFile*/): boolean {
    return false;
  }
}
